import { BrowserRouter } from 'react-router-dom';
import RouterComponent from './Components/Router/RouterComponent';
import StylesWrapperContext from './Theme/StylesWrapperContext';

const App = () => {
  return (
    <BrowserRouter>
      <StylesWrapperContext>
        <RouterComponent />
      </StylesWrapperContext>
    </BrowserRouter>
  );
};

export default App;
