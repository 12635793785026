import { Visibility, VisibilityOff } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { ReactElement, useState } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

interface ICustomInput {
  name: string;
  label: string;
  control: Control<FieldValues>;
  defaultValue?: string;
  required?: boolean;
  type?: string;
}

const CustomInput: React.FC<ICustomInput> = ({
  name,
  label,
  control,
  defaultValue = '',
  required = false,
  type,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          type={type}
          required={required}
          label={label}
          value={value}
          onChange={onChange}
          error={!!error}
          helperText={error ? error.message : null}
          margin="normal"
          size="small"
          variant="outlined"
          fullWidth
          autoFocus
        />
      )}
    />
  );
};

interface CustomAdornmentInputProps {
  icon: ReactElement<any, any>;
  type: string;
  handleClick: () => void;
  readonly?: boolean;
}

const CustomAdornmentInput: React.FC<
  ICustomInput & CustomAdornmentInputProps
> = ({
  name,
  label,
  control,
  defaultValue = '',
  type,
  icon,
  handleClick,
  readonly = false,
}) => {
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
          required
          error={!!error}
        >
          <InputLabel>{label}</InputLabel>
          <OutlinedInput
            label={label}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            required
            error={!!error}
            readOnly={readonly}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClick}
                  onMouseDown={handleMouseDown}
                  sx={{ width: '30px', height: '30px' }}
                >
                  {icon}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      )}
    />
  );
};

const CustomPasswordInput: React.FC<ICustomInput> = ({
  name,
  label,
  control,
  defaultValue = '',
}) => {
  const [showPass, setShowPass] = useState(false);
  const handleClick = () => {
    setShowPass(!showPass);
  };

  return (
    <CustomAdornmentInput
      type={showPass ? 'text' : 'password'}
      name={name}
      label={label}
      control={control}
      handleClick={handleClick}
      defaultValue={defaultValue}
      icon={showPass ? <VisibilityOff /> : <Visibility />}
    />
  );
};

export { CustomAdornmentInput, CustomInput, CustomPasswordInput };
