import createTheme from '@mui/material/styles/createTheme';

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#315266',
      light: '#52768C',
      dark: '#1C3645',
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#52768C',
      light: '#52768C',
      dark: '#315266',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#52768C',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        elevation2: {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        elevation3: {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        elevation4: {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
  },
});

export const scrollbarStyle = (isDarkMode: boolean) => {
  return {
    '::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: isDarkMode ? '#4a4a4a' : '#d9d9d9',
      borderRadius: '5px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: isDarkMode ? '#4a4a4a' : 'rgba(0, 0, 0, 0.08)',
    },
  };
};
