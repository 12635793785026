import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useWindowSize } from '../../Hooks/useWindowSize';

interface ITwoPanelLayout {
  left: React.ReactNode;
  right: React.ReactNode;
  togglePanels?: boolean;
}

const TwoPanelLayout: React.FC<ITwoPanelLayout> = ({
  left,
  right,
  togglePanels = false,
}) => {
  const { height } = useWindowSize();

  return (
    <Grid
      container
      component="main"
      sx={{ height: height }}
      justifyContent="space-between"
    >
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={4}
        component={Paper}
        elevation={6}
        zIndex={2}
        square
        display={{
          xs: togglePanels ? 'none' : 'flex',
          sm: togglePanels ? 'none' : 'flex',
          md: 'flex',
        }}
        direction="column"
        justifyContent="space-between"
        maxHeight="100vh"
      >
        {left}
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={8}
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap={5}
        display={{
          xs: togglePanels ? 'flex' : 'none',
          sm: togglePanels ? 'flex' : 'none',
          md: 'flex',
        }}
        sx={{
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? '#f0f4f6' : t.palette.grey[900],
        }}
        maxHeight="100vh"
        maxWidth="100vw"
      >
        {right}
      </Grid>
    </Grid>
  );
};

export default TwoPanelLayout;
