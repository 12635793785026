import { createContext } from 'react';
import { useWindowSize } from '../../../Hooks/useWindowSize';

export const IsMobileContext = createContext<boolean>(false);

const WindowSizeContextProvider: React.FC = ({ children }) => {
  const windowSize = useWindowSize();

  return (
    <IsMobileContext.Provider
      value={!!windowSize?.width && windowSize?.width < 900}
    >
      {children}
    </IsMobileContext.Provider>
  );
};

export default WindowSizeContextProvider;
