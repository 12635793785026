import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export type ModalMode = 'noorder' | 'nopass' | 'forgotpass';

interface INoOrderCodeModal {
  isModalOpen: boolean;
  setModalOpen: any;
  mode?: ModalMode;
}

const StartScreenModal: React.FC<INoOrderCodeModal> = ({
  isModalOpen,
  setModalOpen,
  mode,
}) => {
  return (
    <Dialog open={isModalOpen}>
      <DialogTitle sx={{ textAlign: 'center' }}>
        {mode === 'noorder' && 'Что делать, если я не знаю код?'}
        {mode === 'nopass' && 'Еще не зарегистрированы?'}
        {mode === 'forgotpass' && 'Забыли пароль?'}
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        {mode === 'noorder' &&
          'Пожалуйста, обратитесь к Вашему менеджеру, который оформлял данный заказ. Он пришлет Вам нужный код'}
        {mode === 'nopass' &&
          'Для получения персонального логина и пароля, пожалуйста, обратитесь к менеджеру вашей компании'}
        {mode === 'forgotpass' &&
          'Для восстановления логина и пароля, пожалуйста, обратитесь к менеджеру вашей компании'}
      </DialogContent>
      <DialogActions sx={{ px: 2 }}>
        <Button
          sx={{ textTransform: 'none' }}
          variant="contained"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          Понятно
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StartScreenModal;
