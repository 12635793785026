import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import FallbackProgress from '../Custom/FallbackProgress';
import ErrorPage from '../Error/ErrorPage';
import Start from '../Start/Start';

const Client = React.lazy(() => import('../Client/Client'));
const Driver = React.lazy(() => import('../Driver/Driver'));

const RouterComponent: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/client" />} />
      <Route path="/client" element={<Start mode="client" />} />
      <Route path="/driver" element={<Start mode="driver" />} />
      <Route
        path="/client/:orderId"
        element={
          <React.Suspense fallback={<FallbackProgress />}>
            <Client />
          </React.Suspense>
        }
      />
      <Route
        path="/driver/:session"
        element={
          <React.Suspense fallback={<FallbackProgress />}>
            <Driver />
          </React.Suspense>
        }
      />
      <Route
        path="/client/error/:error"
        element={<ErrorPage mode={'client'} />}
      />
      <Route
        path="/driver/error/:error"
        element={<ErrorPage mode={'driver'} />}
      />
      <Route path="*" element={<Navigate replace to="/client" />} />
    </Routes>
  );
};

export default RouterComponent;
