import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const FallbackProgress: React.FC = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress disableShrink size={75} />
      <Typography variant="h6">Загрузка</Typography>
    </div>
  );
};

export default FallbackProgress;
