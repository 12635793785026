import Box from '@mui/material/Box';
import ASKBackground from '../../../Assets/ASKBackground.png';
import ASKBackgroundDark from '../../../Assets/ASKBackgroundDark.png';

const ASKBackgroundComponent: React.FC<{ isDark: boolean }> = ({ isDark }) => {
  return (
    <Box
      component="img"
      src={isDark ? ASKBackgroundDark : ASKBackground}
      alt="ASKBackground"
      sx={{
        display: 'flex',
        boxSizing: 'border-box',
        width: '80%',
      }}
    />
  );
};

export default ASKBackgroundComponent;
