import { base64ToUUID } from '../Functions/Base64ToGuid';
import api from './API';

export interface IShipment {
  factory: string;
  factory_location: {
    lat: number;
    lon: number;
  };
  id: string;
  load_begin: string;
  mobject_loc: {
    lat: number;
    lon: number;
  };
  mobject_course: number;
  vehicle: string;
  load_loc: {
    lat: number;
    lon: number;
  };
  number: string;
  status: number;
  unload_begin: string;
  volume: number;
  driver: {
    name: string;
    phone: string;
  };
}

export interface IOrder {
  address: string;
  cargo_name: string;
  exec_date: string;
  id: string;
  number: string;
  shipment_interval: string;
  shipments: IShipment[];
  unload_loc: {
    lat: number;
    lon: number;
  };
}
interface IGetTrackOrder {
  order: IOrder;
  result: string;
  code?: number;
  data?: string;
}

interface IRateOrder {
  result: string;
}

export const getTrackOrder = (id?: string) => {
  return api.get<IGetTrackOrder>(
    `/beton?func=gettrackorder&id=${base64ToUUID(id)}&utc`
  );
};

export const rateOrder = (
  id: string,
  product: number,
  delivery: number,
  recommend: number,
  comment: string
) => {
  return api.get<IRateOrder>(
    `/beton?func=rateorder&id=${id}&product=${product}&delivery=${delivery}&recommend=${recommend}&note=${comment}`
  );
};
